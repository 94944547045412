/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 19:13:34
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 16:42:22
 */
export default {
  name: 'ActivityDetail',
  data () {
    return {
      form: {
        id: '',
        name: '',
        media_platform: '',
        data_type: '',
        monitor_platform: '',
        report_type: '',
        origin_url: '',
        url: ''
      }
    }
  },
  methods: {
    async init () {
      const id = this.$route.params.id
      const data = await this.$wPost('/operate/activity/get.do', { id })
      if (data !== true) {
        for (const i in data) {
          if (data[i]) {
            this.$set(this.form, i, data[i])
          } else {
            this.$set(this.form, i, '-')
          }
        }
      }
    },
    cancel () {
      history.go(-1)
    }
  },
  created () {
    this.init()
  }
}
