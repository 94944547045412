/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 19:13:41
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 19:16:25
 */
import ActivityDetail from './ActivityDetail.vue'
export default ActivityDetail
